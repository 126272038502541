@mixin breakpoint($point) {
    @if $point == small {
        @media (max-width: 616px) { @content; }
    }
    @else if $point == medium {
        @media (max-width: 900px) { @content; }
    }
    @else if $point == large {
        @media screen and  (min-width: 901px) { @content; }
    }
}

/* You can add global styles to this file, and also import other style files */
@import 'assets/scss/mixins.scss';
@import url('assets/scss/fonts.scss');

body {
    background-size: cover;
    background-position: top;
    background-color: #1D2741;
    background-repeat: no-repeat;
    background-image: url('assets/img/background.png');
}

.player-container {
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    align-content: space-between;
    background-color: #FFF;
    justify-content: space-around;

    #video-container {
        width: 47%;
        padding-right: 10px;

        video {
            width: 100%;
            max-width: 100%;
        }

        @include breakpoint(small) {
            width: 100%;
            padding-right: 0px;
        }

        @include breakpoint(medium) {
            width: 100%;
            padding-right: 0px;
        }
    }

    .log {
        width: 47%;
        padding: 20px;
        color: #FFF;
        overflow: auto;
        min-height: 195px;
        max-height: 195px;
        margin-left: 10px;
        border-radius: 5px;
        font-family: 'JetBrains Mono';
        background-color: #07111F;

        @include breakpoint(small) {
            width: 100%;
            margin-left: 0;
        }

        @include breakpoint(medium) {
            width: 100%;
            margin-left: 0;
        }
    }

    .code-container {
        width: 100%;

        pre code {
            border-radius: 10px;
            background-color: #F6F6F6;
        }
    }
}
